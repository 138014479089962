html,
body {
  height: 100%;
}

.error {
  &-layout {
    display: flex;
    align-items: center;
  }

  &-content {
    padding: 110px 20px;
    background: #FFFFFF;
  }

  &__title {
    margin-bottom: 28px;
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    line-height: 1;
  }

  &__desc {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 1;
  }

  &__button {
    margin-top: 38px;
  }

  &__image {
    width: 100%;
    height: auto;
    margin-top: 60px;
  }
}
