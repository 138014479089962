/* line 1, src/layout/error/error.scss */
html,
body {
  height: 100%;
}

/* line 7, src/layout/error/error.scss */
.error-layout {
  display: flex;
  align-items: center;
}

/* line 12, src/layout/error/error.scss */
.error-content {
  padding: 110px 20px;
  background: #FFFFFF;
}

/* line 17, src/layout/error/error.scss */
.error__title {
  margin-bottom: 28px;
  font-size: 48px;
  font-weight: bold;
  color: #000000;
  line-height: 1;
}

/* line 25, src/layout/error/error.scss */
.error__desc {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 1;
}

/* line 33, src/layout/error/error.scss */
.error__button {
  margin-top: 38px;
}

/* line 37, src/layout/error/error.scss */
.error__image {
  width: 100%;
  height: auto;
  margin-top: 60px;
}
